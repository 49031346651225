import { Worksheet, Row } from "exceljs";
import { filter } from "lodash";

export type json = { [key: string]: any };

const excelToJSON = (worksheet: Worksheet): json => {
  const dataArray: json[] = [];
  let keys: string[];
  worksheet.eachRow(function (row, rowNumber) {
    if (rowNumber === 1) {
      keys = filter(
        JSON.parse(JSON.stringify(row.values)),
        (val) => val !== null && val !== undefined
      );
    } else {
      let rowDict = cellValueToMap(keys, row);
      dataArray.push(rowDict);
    }
  });
  return dataArray;
};

const cellValueToMap = (keys: string[], row: Row) => {
  const data: json = {};
  row.eachCell(function (cell, colNumber) {
    let cellValue = cell.value;
    // let value: string = "";
    // if (typeof cellValue === 'object') {
    //   if (cellValue) {
    //     value = cellValue.toString().trim();
    //   }
    // }
    data[keys[colNumber - 1]] = cellValue?.toString();
  });
  return data;
};

const defaultExports = {
  toJSON: excelToJSON,
};

export default defaultExports;
