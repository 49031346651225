export enum DELIVERY_METHOD {
  ZEEK = "ZEEK",
  SF_EXPRESS = "SF_EXPRESS",
  FO_TAN_PICKUP = "FO_TAN_PICKUP",
  KWAI_CHUNG_PICKUP = "KWAI_CHUNG_PICKUP",
  KWUN_TONG_PICKUP = "KWUN_TONG_PICKUP",
  ANY_JEWELLERY_SHEUNG_WAN_PICKUP = "ANY_JEWELLERY_SHEUNG_WAN_PICKUP",
  STANDARD = "STANDARD",
  UNKNOWN = "UNKNOWN",
}

export interface DELIVERY_METHOD_INFO {
  code: string;
  name: string;
  displayName: string;
}

export const DELIVERY_METHOD_INFO_LIST: Record<string, DELIVERY_METHOD_INFO> = {
  ZEEK: {
    code: "Z",
    name: "斑馬Zeek",
    displayName: "Zeek",
  },
  SF_EXPRESS: {
    code: "SF",
    name: "順豐SF",
    displayName: "順豐",
  },
  FO_TAN_PICKUP: {
    code: "P",
    name: "自取ST",
    displayName: "火炭自取",
  },
  KWAI_CHUNG_PICKUP: {
    code: "KH",
    name: "自取KH",
    displayName: "葵興自取",
  },
  KWUN_TONG_PICKUP: {
    code: "KT",
    name: "自取KT",
    displayName: "觀塘自取",
  },
  ANY_JEWELLERY_SHEUNG_WAN_PICKUP: {
    code: "ANY",
    name: "自取ANY",
    displayName: "ANY Jewellery上環",
  },
  STANDARD: {
    code: "C",
    name: "標準派送",
    displayName: "標準派送",
  },
  UNKNOWN: {
    code: "UNKNOWN",
    name: "UNKNOWN",
    displayName: "Unknown",
  },
};
