import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.global.css";

import Home from "./components/Home";
import Menu from "./components/Menu";
import Delivery from "./components/Delivery";
import DeliveryAirbox from "./components/DeliveryAirbox";

export default function App() {
  return (
    <Router basename="/">
      <Switch>
        <Route path="/menu" exact component={Menu} />
        <Route path="/delivery-report" exact component={Delivery} />
        <Route
          path="/delivery-airbox-report"
          exact
          component={DeliveryAirbox}
        />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}
