import fs from "fs";
import * as ExcelJS from "exceljs";
import { groupBy, uniq, findIndex, sortBy } from "lodash";
import ExcelUtils from "../utils/excel";
import {
  DELIVERY_METHOD,
  DELIVERY_METHOD_INFO,
  DELIVERY_METHOD_INFO_LIST,
} from "./delivery.constant";

export type Json = Record<string, any>;
export type ReportOptions = {
  alternateRow: boolean;
};

class ShipAirImpl {
  public orderWorkbook: ExcelJS.Workbook;

  public orderSheets: Json[];

  public airWorkbook: ExcelJS.Workbook;

  public airSheets: Json[];

  public outDir: string;

  public outOrderWorkbook: ExcelJS.Workbook;

  public outAirWorkbook: ExcelJS.Workbook;

  private headerStyle: Json;

  private rowStyle: Json;

  private alertPhoneNumbers: string[];

  public isWebMode: boolean;

  public constructor() {
    this.isWebMode = true;

    this.orderWorkbook = new ExcelJS.Workbook();
    this.orderSheets = [];

    this.airWorkbook = new ExcelJS.Workbook();
    this.airSheets = [];

    this.outDir = "./out";

    this.outOrderWorkbook = new ExcelJS.Workbook();
    this.outAirWorkbook = new ExcelJS.Workbook();

    this.alertPhoneNumbers = [];

    this.headerStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" },
      },
      font: {
        name: "Calibri",
        underline: "double",
        bold: true,
      },
    };

    this.rowStyle = {
      border: {
        top: { style: "thin", color: { argb: "00000" } },
      },
      alternateFill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" },
      },
      alertFill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF7F50" },
      },
    };
  }

  public async init(
    orderFileName: string,
    airFileName: string,
    outDir: string
  ) {
    // Read order workbook
    await this.orderWorkbook.xlsx.readFile(orderFileName);
    this.orderSheets = this.getSheets(this.orderWorkbook.worksheets);
    this.identifyAlertCases();

    // Read air workbook
    await this.airWorkbook.xlsx.readFile(airFileName);
    this.airSheets = this.getSheets(this.airWorkbook.worksheets);
    // console.log(this.airSheets[0].data);

    this.outDir = outDir;

    if (!this.isWebMode) {
      if (!fs.existsSync(this.outDir)) {
        fs.mkdirSync(this.outDir);
      }
    }
  }

  public async initBuffer(
    orderFileBuffer: ArrayBuffer,
    airFileBuffer: ArrayBuffer,
    outDir: string
  ) {
    // Read order workbook
    await this.orderWorkbook.xlsx.load(orderFileBuffer);
    this.orderSheets = this.getSheets(this.orderWorkbook.worksheets);
    this.identifyAlertCases();

    // Read air workbook
    await this.airWorkbook.xlsx.load(airFileBuffer);
    this.airSheets = this.getSheets(this.airWorkbook.worksheets);
    // console.log(this.airSheets[0].data);

    this.outDir = outDir;
    if (!this.isWebMode) {
      if (!fs.existsSync(this.outDir)) {
        fs.mkdirSync(this.outDir);
      }
    }
  }

  public getSheets(worksheets: ExcelJS.Worksheet[]) {
    const data = worksheets.map((sheet) => {
      return {
        name: sheet.name,
        data: ExcelUtils.toJSON(sheet),
      };
    });
    return data;
  }

  // public createWorksheet(header: string[], rows: { [key: string]: any }[]) {
  //   this.newWorkbook.addWorksheet('Sales Summary');
  // }

  private mapDeliveryMethod(deliveryMethod: string): DELIVERY_METHOD {
    if (deliveryMethod.indexOf("Zeek") > -1) {
      return DELIVERY_METHOD.ZEEK;
    }
    if (deliveryMethod.indexOf("順豐") > -1) {
      return DELIVERY_METHOD.SF_EXPRESS;
    }
    if (
      deliveryMethod.indexOf("火炭") > -1 &&
      deliveryMethod.indexOf("自取") > -1
    ) {
      return DELIVERY_METHOD.FO_TAN_PICKUP;
    }
    if (
      deliveryMethod.indexOf("葵涌") > -1 &&
      deliveryMethod.indexOf("自取") > -1
    ) {
      return DELIVERY_METHOD.KWAI_CHUNG_PICKUP;
    }

    if (
      deliveryMethod.indexOf("觀塘") > -1 &&
      deliveryMethod.indexOf("自取") > -1
    ) {
      return DELIVERY_METHOD.KWUN_TONG_PICKUP;
    }
    if (
      deliveryMethod.indexOf("ANY Jewellery上環") > -1 &&
      deliveryMethod.indexOf("自取") > -1
    ) {
      return DELIVERY_METHOD.ANY_JEWELLERY_SHEUNG_WAN_PICKUP;
    }

    if (
      deliveryMethod.indexOf("標準配送") > -1 ||
      deliveryMethod.indexOf("標準派送") > -1
    ) {
      return DELIVERY_METHOD.STANDARD;
    }

    return DELIVERY_METHOD.UNKNOWN;
  }

  public identifyAlertCases(): void {
    const rows = this.orderSheets.reduce((acc, curr) => {
      return acc.concat(curr.data);
    }, []);

    const groupedCustomerData: Json = groupBy(rows, (row: any) => {
      const receipientPhoneNumber = String(row["收件人電話號碼"]);
      // const receipientPhoneNumber = row['Recipient Phone Number'];
      // const addr1 = row['地址 1'];
      // const addr2 = row['地址 2'];
      // const productName = row['Product Name'];
      // const variant = row['Variation'];
      // const orderNumber = row['Order Number'];
      // return `${customerName}|${addr}|${productName}|${variant}`;
      return receipientPhoneNumber.replace(/[\s-]/g, "");
    });

    for (const [key, values] of Object.entries(groupedCustomerData)) {
      const phoneNumber = key;
      const deliveryMethods = values.map((val: any) => {
        const deliveryMethodInfo = this.mapDeliveryMethod(
          String(val["送貨方式"])
        );
      });

      const adddresses = values.map((val: any) => val["地址 1"]);

      const uniqDeliveryMethods = uniq(deliveryMethods);
      const uniqAddresses = uniq(adddresses);

      if (uniqDeliveryMethods.length > 1 || uniqAddresses.length > 1) {
        // console.log(`${phoneNumber} : ${uniqDeliveryMethods.length}`);
        // console.log(uniqDeliveryMethods);
        this.alertPhoneNumbers.push(phoneNumber);
      }
    }
  }

  private renderAlertCasesSheet() {
    this.outOrderWorkbook.addWorksheet("Alert Cases");
  }

  private isAlertCase(phoneNumber: string) {
    const cleanedPhoneNumber = phoneNumber.replace(/[\s-]/g, "");
    return this.alertPhoneNumbers.includes(cleanedPhoneNumber);
  }

  private groupDeliveryMethods(sheets: Json[]) {
    const orders = sheets.reduce((acc, curr) => {
      return acc.concat(curr.data);
    }, []);

    // console.log(orders);
    const orderByDeliveryMethod: Json = groupBy(orders, (row: any) => {
      const deliveryMethod: DELIVERY_METHOD = this.mapDeliveryMethod(
        String(row["送貨方式"])
      );
      const deliveryMethodInfo: DELIVERY_METHOD_INFO =
        DELIVERY_METHOD_INFO_LIST[deliveryMethod];
      return `${deliveryMethodInfo.code}|${deliveryMethodInfo.name}`;
    });

    const sheetData = [];
    for (const [key, data] of Object.entries(orderByDeliveryMethod)) {
      const [code, deliveryMethod] = key.split("|");
      sheetData.push({
        code,
        name: deliveryMethod,
        data,
      });
    }

    return sheetData;
  }

  private groupAirSheets(code: string, sheets: Json[]) {
    let airboxes: Json[] = [];

    for (const sheet of sheets) {
      airboxes = airboxes.concat(sheet.data);
    }

    return {
      code,
      name: "airbox",
      data: airboxes,
    };
  }

  private async writeAirSheet(sheet: Json, outFileName: string) {
    const airWorkbook = this.outAirWorkbook;
    const airWorksheet = airWorkbook.addWorksheet("空運表");

    airWorksheet.columns = [
      { header: "空運組", key: "空運組" },
      { header: "板數", key: "板數" },
      { header: "出貨裝箱單", key: "出貨裝箱單" },
      { header: "直播時間出貨方式", key: "直播時間出貨方式" },
      { header: "訂單號碼", key: "訂單號碼" },
      { header: "訂單組", key: "訂單組" },
      { header: "參考編號", key: "參考編號" },
      { header: "備註", key: "備註" },
    ];

    for (const row of sheet.data) {
      const formattedOrder = {
        空運組: row["空運組"],
        板數: row["板數"],
        出貨裝箱單: row["出貨裝箱單"],
        直播時間出貨方式: row["直播時間出貨方式"],
        訂單號碼: String(row["訂單號碼"]).replace(/[#\s]/g, ""),
        訂單組: row["訂單組"] || "",
        參考編號: row["參考編號"] || "",
        備註: row["備註"],
      };
      airWorksheet.addRow(formattedOrder);
    }

    // Set Header Style
    const headerRow = airWorksheet.getRow(1);
    headerRow.fill = this.headerStyle.fill;
    headerRow.font = this.headerStyle.font;

    for (const column of airWorksheet.columns) {
      let maxLength = 0;
      if (column.eachCell) {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : Math.min(maxLength, 20);
        // column.width = 15;
      }
    }

    if (!this.isWebMode) {
      await airWorkbook.xlsx.writeFile(
        `${this.outDir}/${outFileName || "Air Report.xlsx"}`
      );
    }
  }

  private countProducts(sheets: Json[]) {
    // merge multiple sheets into one
    const orders = sheets.reduce((acc, curr) => {
      return acc.concat(curr.data);
    }, []);

    const sortedOrders = sortBy(orders, ["商品名稱", "選項", "送貨方式"]);
    const productStats: any = {};

    for (const order of sortedOrders) {
      const productCode = `${order["商品名稱"]}|${order["選項"] || ""}`;
      const deliveryMethod = this.mapDeliveryMethod(String(order["送貨方式"]));

      if (!productStats[productCode]) {
        productStats[productCode] = {
          total: 0,
          [DELIVERY_METHOD.ZEEK]: 0,
          [DELIVERY_METHOD.SF_EXPRESS]: 0,
          [DELIVERY_METHOD.FO_TAN_PICKUP]: 0,
          [DELIVERY_METHOD.KWAI_CHUNG_PICKUP]: 0,
          [DELIVERY_METHOD.KWUN_TONG_PICKUP]: 0,
          [DELIVERY_METHOD.ANY_JEWELLERY_SHEUNG_WAN_PICKUP]: 0,
          [DELIVERY_METHOD.STANDARD]: 0,
          [DELIVERY_METHOD.UNKNOWN]: 0,
        };
      }

      const count = Number.parseInt(order["數量"], 10);
      productStats[productCode][deliveryMethod] += count;
      productStats[productCode]["total"] += count;
    }
    return productStats;
  }

  public async generateSalesReportEx(
    airId: string,
    batchId: string,
    outFileName: string,
    outAirFileName: string,
    // sheetName: string = 'Sheet',
    options: ReportOptions = {
      alternateRow: false,
    }
  ): Promise<void> {
    try {
      const deliverySheets = this.groupDeliveryMethods(this.orderSheets);
      const airSheets = this.groupAirSheets(airId, this.airSheets);

      for (const sheet of deliverySheets) {
        const { code: sheetCode, name: sheetName, data: sheetData } = sheet;
        const groupedCustomerData: Json = groupBy(sheetData, (row: any) => {
          const receipientPhoneNumber = row["收件人電話號碼"];
          // const receipientPhoneNumber = row['Recipient Phone Number'];
          const addr1 = row["地址 1"];
          // const addr2 = row['地址 2'];
          // const productName = row['Product Name'];
          // const variant = row['Variation'];
          // const orderNumber = row['Order Number'];
          // return `${customerName}|${addr}|${productName}|${variant}`;
          return `${receipientPhoneNumber}|${addr1}`;
        });

        const rowData = [];

        // Set column keys
        const newWorksheet = this.outOrderWorkbook.addWorksheet(sheetName);
        newWorksheet.columns = [
          { header: "空運組", key: "空運組" },
          { header: "空運箱", key: "空運箱" },
          { header: "訂單組", key: "訂單組" },
          { header: "參考編號", key: "參考編號" },
          { header: "注意", key: "注意" },
          { header: "訂單號碼", key: "訂單號碼" },
          { header: "訂單日期", key: "訂單日期" },
          { header: "訂單狀態", key: "訂單狀態" },
          { header: "付款狀態", key: "付款狀態" },
          { header: "運費", key: "運費" },
          { header: "送貨方式", key: "送貨方式" },
          { header: "送貨狀態", key: "送貨狀態" },
          { header: "收件人", key: "收件人" },
          { header: "收件人電話號碼", key: "收件人電話號碼" },
          { header: "地址 1", key: "地址 1" },
          { header: "地址 2", key: "地址 2" },
          { header: "城市", key: "城市" },
          { header: "商品名稱", key: "商品名稱" },
          { header: "選項", key: "選項" },
          { header: "數量", key: "數量" },
          { header: "訂單備註", key: "訂單備註" },
        ];

        let isNewGroup = true;
        let groupCount = 1;
        let groupRowCount = 0;
        for (const [, customerOrders] of Object.entries(groupedCustomerData)) {
          let orderGroup = `${batchId}${sheetCode}#${groupCount
            .toString()
            .padStart(3, "0")}`;
          if (customerOrders.length > 1) {
            const uniqOrders = uniq(
              customerOrders.map((o: any) => o["訂單號碼"])
            );
            orderGroup = `${orderGroup}(${uniqOrders.length})`;
          }

          for (const order of customerOrders) {
            const isFirstRowInGroup = groupRowCount === 0;
            const isAlertCase = this.isAlertCase(order["收件人電話號碼"]);

            // Find airbox
            //   console.log(airSheets.data);
            const airboxIndex = findIndex(airSheets.data, (row: any) => {
              const orderNumber = String(order["訂單號碼"]).replace(
                /[#\s]/g,
                ""
              );
              const boxOrderNumber = String(row["訂單號碼"]).replace(
                /[#\s]/g,
                ""
              );
              return orderNumber === boxOrderNumber;
            });

            let airboxInfo;
            if (airboxIndex > -1) {
              airboxInfo = airSheets.data[airboxIndex];
              airboxInfo["訂單組"] = orderGroup;
            } else {
              airboxInfo = {};
            }

            const formattedOrder = {
              空運組: airboxInfo["空運組"] || "",
              空運箱: airboxInfo["出貨裝箱單"] || "",
              訂單組: isFirstRowInGroup ? orderGroup : "",
              參考編號: isFirstRowInGroup ? airboxInfo["參考編號"] : "",
              注意: isFirstRowInGroup && isAlertCase ? "Yes" : "",
              訂單號碼: String(order["訂單號碼"]).replace(/[#\s]/g, ""),
              訂單日期: new Date(order["訂單日期"]),
              訂單狀態: order["訂單狀態"] || "",
              付款狀態: order["付款狀態"] || "",
              運費: order["運費"] || "",
              送貨方式: isFirstRowInGroup ? order["送貨方式"] : "",
              // 送貨方式: order['送貨方式'],
              送貨狀態: order["送貨狀態"] || "",
              收件人: isFirstRowInGroup ? order["收件人"] : "",
              收件人電話號碼: isFirstRowInGroup ? order["收件人電話號碼"] : "",
              // 收件人電話號碼: order['收件人電話號碼'],
              "地址 1": isFirstRowInGroup ? order["地址 1"] : "",
              "地址 2": isFirstRowInGroup ? order["地址 2"] : "",
              // '地址 2': order['地址 2'],
              城市: isFirstRowInGroup ? order["城市"] : "",
              商品名稱: order["商品名稱"],
              選項: order["選項"],
              數量: Number.parseInt(order["數量"], 10),
              訂單備註: order["訂單備註"],
            };
            const newRow = newWorksheet.addRow(formattedOrder);

            // Render alternating rows with color
            if (options.alternateRow && isNewGroup) {
              newRow.fill = this.rowStyle.alternateFill;
            }

            if (isAlertCase) {
              newRow.fill = this.rowStyle.alertFill;
            }

            if (isFirstRowInGroup) {
              newRow.border = this.rowStyle.border;
            }

            groupRowCount += 1;
            rowData.push(formattedOrder);
          }
          groupCount += 1;
          groupRowCount = 0;
          isNewGroup = !isNewGroup;
        }

        // Set Header Style
        const headerRow = newWorksheet.getRow(1);
        headerRow.fill = this.headerStyle.fill;
        headerRow.font = this.headerStyle.font;

        for (const column of newWorksheet.columns) {
          let maxLength = 0;
          if (column.eachCell) {
            column.eachCell({ includeEmpty: true }, (cell) => {
              const columnLength = cell.value
                ? cell.value.toString().length
                : 10;
              if (columnLength > maxLength) {
                maxLength = columnLength;
              }
            });
            column.width = maxLength < 10 ? 10 : Math.min(maxLength, 20);
            // column.width = 15;
          }
        }
      }

      // Product count
      const productStats: Json = this.countProducts(this.orderSheets);
      // Set column keys
      const productStatsWB = this.outOrderWorkbook.addWorksheet("統計");
      productStatsWB.columns = [
        { header: "商品名稱", key: "商品名稱" },
        { header: "選項", key: "選項" },
        {
          header: DELIVERY_METHOD_INFO_LIST.FO_TAN_PICKUP.displayName,
          key: DELIVERY_METHOD_INFO_LIST.FO_TAN_PICKUP.name,
        },
        {
          header: DELIVERY_METHOD_INFO_LIST.KWAI_CHUNG_PICKUP.displayName,
          key: DELIVERY_METHOD_INFO_LIST.KWAI_CHUNG_PICKUP.name,
        },
        {
          header: DELIVERY_METHOD_INFO_LIST.KWUN_TONG_PICKUP.displayName,
          key: DELIVERY_METHOD_INFO_LIST.KWUN_TONG_PICKUP.name,
        },
        {
          header:
            DELIVERY_METHOD_INFO_LIST.ANY_JEWELLERY_SHEUNG_WAN_PICKUP
              .displayName,
          key: DELIVERY_METHOD_INFO_LIST.ANY_JEWELLERY_SHEUNG_WAN_PICKUP.name,
        },
        {
          header: DELIVERY_METHOD_INFO_LIST.SF_EXPRESS.displayName,
          key: DELIVERY_METHOD_INFO_LIST.SF_EXPRESS.name,
        },
        {
          header: DELIVERY_METHOD_INFO_LIST.ZEEK.displayName,
          key: DELIVERY_METHOD_INFO_LIST.ZEEK.name,
        },
        {
          header: DELIVERY_METHOD_INFO_LIST.STANDARD.displayName,
          key: DELIVERY_METHOD_INFO_LIST.STANDARD.name,
        },
        // { header: '未知', key: 'UNKNOWN' },
        { header: "總數", key: "總數" },
      ];

      for (const [key, data] of Object.entries(productStats)) {
        const productName = key.split("|")[0];
        const productVar = key.split("|")[1];
        const statRow = {
          商品名稱: productName ? productName : "",
          選項: productVar ? productVar : "",
          自取ST:
            data[DELIVERY_METHOD.FO_TAN_PICKUP] > 0
              ? data[DELIVERY_METHOD.FO_TAN_PICKUP]
              : "",
          自取KH:
            data[DELIVERY_METHOD.KWAI_CHUNG_PICKUP] > 0
              ? data[DELIVERY_METHOD.KWAI_CHUNG_PICKUP]
              : "",
          順豐SF:
            data[DELIVERY_METHOD.SF_EXPRESS] > 0
              ? data[DELIVERY_METHOD.SF_EXPRESS]
              : "",
          自取KT:
            data[DELIVERY_METHOD.KWUN_TONG_PICKUP] > 0
              ? data[DELIVERY_METHOD.KWUN_TONG_PICKUP]
              : "",
          自取ANY:
            data[DELIVERY_METHOD.ANY_JEWELLERY_SHEUNG_WAN_PICKUP] > 0
              ? data[DELIVERY_METHOD.ANY_JEWELLERY_SHEUNG_WAN_PICKUP]
              : "",
          斑馬Zeek:
            data[DELIVERY_METHOD.ZEEK] > 0 ? data[DELIVERY_METHOD.ZEEK] : "",
          標準派送:
            data[DELIVERY_METHOD.STANDARD] > 0
              ? data[DELIVERY_METHOD.STANDARD]
              : "",
          UNKNOWN:
            data[DELIVERY_METHOD.UNKNOWN] > 0
              ? data[DELIVERY_METHOD.UNKNOWN]
              : "",
          總數: data["total"] > 0 ? data["total"] : "",
        };
        productStatsWB.addRow(statRow);
      }

      await this.writeAirSheet(airSheets, outAirFileName);

      if (!this.isWebMode) {
        await this.outOrderWorkbook.xlsx.writeFile(
          `${this.outDir}/${outFileName || "Shipping Report.xlsx"}`
        );
      }
    } catch (err) {
      alert(err);
    }
  }
}

// const run = async () => {
//   // const SOURCE_FILE = './raw/4月16日馬拉松_All.xlsx';
//   // const SOURCE_FILE = './raw/出貨  4月份 Live_Coach_20210502.xlsx';
//   //   const ORDER_FILE = './raw/出貨  4月份 Live_Easter_20210502.xlsx';
//   const fileSet = {
//     easter_0404: {
//       orderFile: './raw/出貨  4月份 Live_Easter_20210502.xlsx',
//       airFile: './raw/air92_93.xlsx',
//       airCode: 'AIR092',
//       outputCode: 'HE',
//       outputFile: '出貨  4月份 Live_Easter_20210502_report.xlsx',
//       outputAirFile: '空運表_92_93.xlsx',
//     },
//     coach_0409: {
//       orderFile: './raw/出貨  4月份 Live_Coach_20210502.xlsx',
//       airFile: './out/空運表_92_93.xlsx',
//       airCode: 'AIR093',
//       outputCode: 'P',
//       outputFile: '出貨  4月份 Live_Coach_20210502_report.xlsx',
//       outputAirFile: '空運表_92_93.xlsx',
//     },
//   };

//   const {
//     orderFile,
//     airFile,
//     airCode,
//     outputCode,
//     outputFile,
//     outputAirFile,
//   } = fileSet.coach_0409;
//   const excelObj = new ExcelImpl();
//   await excelObj.init(orderFile, airFile);
//   await excelObj.generateSalesReportEx(
//     airCode,
//     outputCode,
//     outputFile,
//     outputAirFile
//   );
// };

// run();

export default ShipAirImpl;
