import React from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";

const NewDropzone = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {/* {`${file.path.length > 50? file.path.substring(1,20): file.path} ...`} - {file.size} bytes */}
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="dropzone container dropzone__container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="dropzone__drop_area">
          Drag 'n' drop some files here, or click to select files
        </div>
      </div>
      <div>
        <h4>Files</h4>
        <ul>{files}</ul>
      </div>
    </section>
  );
};

const Delivery = () => {
  return (
    <div className="page__container">
      <h1>出貨表</h1>
      <div>
        <NewDropzone />
      </div>
      <div>
        <Link className="btn--center" to="/menu">
          <button>返回</button>
        </Link>
      </div>
    </div>
  );
};

export default Delivery;
