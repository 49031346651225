import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import DropFileZone from "./DropFileZone";
import PorcessDeliveryAirboxMod from "../modules/processDeliveryAirbox";

const DeliveryAir = () => {
  const [batchId, setBatchId] = useState<string>("");
  const [orderFile, setOrderFile] = useState<File>();
  const [orderFileBuffer, setOrderFileBuffer] = useState<ArrayBuffer>();
  const [airboxFileBuffer, setairboxFileBuffer] = useState<ArrayBuffer>();
  const [airboxFile, setAirboxFile] = useState<File>();
  const [, setOutputFile] = useState<any[]>([]);
  const [status, setStatus] = useState<string>("NOT_STARTED");

  const outDir = "./output_reports";

  const onOrderFileDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      setOrderFile(acceptedFiles[0]);
      setOrderFileBuffer(binaryStr as ArrayBuffer);
    };
    reader.readAsArrayBuffer(file);
  }, []);

  const onAirboxFileDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      setAirboxFile(acceptedFiles[0]);
      setairboxFileBuffer(binaryStr as ArrayBuffer);
    };
    reader.readAsArrayBuffer(file);
  }, []);

  const handleChange = (event: any) => {
    setBatchId(event.target.value);
  };

  const processFile = async () => {
    setStatus("STARTED");
    const { name: orderFileName } = orderFile as any;
    const { name: airboxFileName } = airboxFile as any;

    const processor = new PorcessDeliveryAirboxMod();
    if (!orderFileBuffer || !airboxFileBuffer) {
      console.log(`Order or airbox file cannot be null`);
      return;
    }
    await processor.initBuffer(orderFileBuffer, airboxFileBuffer, outDir);
    await processor.generateSalesReportEx(
      "",
      batchId,
      `output_${orderFileName}`,
      `output_${airboxFileName}`
    );

    function saveByteArray(reportName: string, buffer: ArrayBuffer) {
      var blob = new Blob([buffer], {
        type: "aapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    }
    const orderBuffer = await processor.outOrderWorkbook.xlsx.writeBuffer();
    const airboxBuffer = await processor.outAirWorkbook.xlsx.writeBuffer();
    saveByteArray(orderFileName, orderBuffer);
    saveByteArray(airboxFileName, airboxBuffer);

    setStatus("COMPLETED");
    setOutputFile([
      { name: "訂單表", path: `${outDir}/output_${orderFileName}` },
      { name: "空運表", path: `${outDir}/output_${airboxFileName}` },
    ]);
  };

  return (
    <div className="page__container">
      <h1>出貨空運單</h1>
      <div className="margin-top-16 margin-bottom-16">
        <div>批次碼</div>
        <input type="text" value={batchId} onChange={handleChange} />
      </div>
      <div className="margin-top-16 margin-bottom-16">
        <div>訂單表</div>
        <DropFileZone onDrop={onOrderFileDrop} />
      </div>
      <div className="margin-top-16 margin-bottom-16">
        <div>空運表</div>
        <DropFileZone onDrop={onAirboxFileDrop} />
      </div>
      <div className="btnGroup">
        <div className="btnGroup__btn">
          <Link className="btn--center" to="/menu">
            <button type="button">返回</button>
          </Link>
        </div>
        <div className="btnGroup__btn">
          <button className="btn--center" type="button" onClick={processFile}>
            確定
          </button>
        </div>
      </div>

      {status === "STARTED" ? <div>Processing...</div> : null}
      {status === "COMPLETED" ? (
        <div className="outFileGroup">
          <div>完成</div>
          {/* {outputFiles.map((outputFile, index) => {
            const keyIndex = index;
            const { name, path } = outputFile as any;
            return (
              <div
                key={`outputFile_${keyIndex}`}
                className="outFileGroup__item"
              >
                <a href={path} target="_blank" rel="noreferrer">
                  {`${name}: ${path}`}
                </a>
              </div>
            );
          })} */}
        </div>
      ) : null}
    </div>
  );
};

export default DeliveryAir;
