import React from "react";
import { Link } from "react-router-dom";
import icon from "../assets/icon.svg";

const Home = () => {
  return (
    <div className="page__container">
      <div className="headline">
        <img className="headline_icon" width="200px" alt="icon" src={icon} />
      </div>
      <h1 className="center">Report Converter</h1>
      <div>
        <Link className="btn--center" to="/menu">
          <button>開始</button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
