import React from 'react';
import { Link } from 'react-router-dom';

const Menu = () => {
  return (
    <div className="page__container">
      <h1>Menu</h1>
      <div>
        {/* <Link className="btn" to="/delivery-report">
          <button>出貨單</button>
        </Link> */}
        <Link className="btn" to="/delivery-airbox-report">
          <button type="button">出貨空運單</button>
        </Link>
      </div>
    </div>
  );
};

export default Menu;
