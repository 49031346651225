import React from "react";
import { useDropzone } from "react-dropzone";
import UpArrow from "../assets/icons/up-arrow.svg";
import { DropFile } from "../types/file.type";

interface DropzoneProps {
  onDrop: (acceptedFiles: File[]) => any;
}

const DropFileZone = ({ onDrop }: DropzoneProps) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const selectedFile: DropFile | null = acceptedFiles ? acceptedFiles[0] : null;
  console.log(selectedFile);

  return (
    <section className="dropzone container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="dropzone__drop_area">
          {/* 拖拽檔案到此處，或單擊以選擇文件。 */}
          <img alt="upload" width="50px" src={UpArrow} />
        </div>
        <div>{selectedFile ? selectedFile.path : ""}</div>
      </div>
    </section>
  );
};

export default DropFileZone;
